import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageBanner } from 'app/page-banner/component/PageBanner';
import { createPortal } from 'react-dom';
import {
    CONTINUE,
    ORDERS,
    PAYMENT_OK_TEXT,
    PAYMENT_OK_TITLE,
    PAYMENT_SCREEN_ADVICE,
} from '../../../../../../i18n/translations/TR';
import { CActionButton } from '../../../../../shared/components/CActionButton/CActionButton';
import { TencerRoutes } from '../../../../../shared/tencer-routing/TencerRoutes';
import { ReactComponent as IconCheck } from '../../../../../../assets/img/icons/ic-check-done.svg';
import { usePaymentOkController } from './usePaymentOkController';
import PageBannerModel from '../../../../../page-banner/model/PageBanner';
import { URL } from '../../../../../shared/domain/value-objects/URL';
import { Spinner } from '../../../../../shared/Spinner';

interface Props {
    koURL: string,
}

export const PaymentOk: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const controller = usePaymentOkController({
        koURL: props.koURL,
    });

    return controller.checkingOut ?
        createPortal(
            <>
                <PageBanner
                    disabled
                    pageId="payment-ok-screen"
                    forcedMessage={
                        new PageBannerModel(
                            t(PAYMENT_SCREEN_ADVICE),
                            new URL(''),
                        )
                    }
                    classes={['danger']}
                />
                <Spinner classCss="basic-spinner-container" />
            </>,
            document.body,
        ) : (
            <div className="payment-result payment-result-ok">
                <div className="header">
                    <div className="title">
                        <h1 className="section-title">
                            <span className="icon"><IconCheck /></span>
                            <span className="text">{t(PAYMENT_OK_TITLE)}</span>
                        </h1>
                    </div>
                </div>
                <div className="body">
                    <p>{t(PAYMENT_OK_TEXT)}</p>
                    <div className="actions">
                        <CActionButton
                            text={t(CONTINUE)}
                            onClick={
                                (): void => { navigate(TencerRoutes.CATALOG); }
                            }
                        />
                        <CActionButton
                            text={t(ORDERS)}
                            onClick={
                                (): void => { navigate(TencerRoutes.ORDERS); }
                            }
                            className="btn-primary-outline"
                        />
                    </div>
                </div>
            </div>
        );
};
