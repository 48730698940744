import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { useIsMounted } from 'app/shared/hooks/useIsMounted';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PageBannerModel from '../model/PageBanner';
import i18n from '../../../i18n';
import { Spinner } from '../../shared/Spinner';

interface Props {
    pageId: string;
    forcedMessage?: PageBannerModel;
    classes?: string[];
    disabled?: boolean
}

export const PageBanner: React.FC<Props> = (
    {
        pageId,
        forcedMessage,
        classes = [],
        disabled = false,
    }: Props,
) => {
    const [banner, setBanner] = useState<PageBannerModel | null>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isMounted = useIsMounted();

    useEffect(() => {
        setIsLoading(true);
        if (forcedMessage) {
            setBanner(forcedMessage);
            setIsLoading(false);
            return;
        }
        TencerApiClientSingleton.getInstance()
            .getPageBanner(pageId)
            .then((response) => {
                if (!isMounted()) {
                    return;
                }
                setBanner(response);
            })
            .catch(() => {
                if (!isMounted()) {
                    return;
                }
                setBanner(null);
            })
            .finally((): void => {
                if (!isMounted()) {
                    return;
                }
                setIsLoading(false);
            });
    }, [pageId, i18n.language, forcedMessage]);

    return banner ? (
        <div className={classNames('page-banner', ...classes)}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading ? (
                <Spinner classCss="" />
            ) :
                banner.link && !disabled ? (
                    <a href={banner.link.value} className="page-banner-link">
                        <span>{banner.text}</span>
                    </a>
                ) : (
                    <span>{banner.text}</span>
                )}
        </div>
    ) : null;
};
